import(/* webpackMode: "eager" */ "/Users/wes/Workspace/in2event/in2event-front-end/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin-_2mggkpqqebct5fvtffn5hjli6e/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/Users/wes/Workspace/in2event/in2event-front-end/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin-_2mggkpqqebct5fvtffn5hjli6e/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/Users/wes/Workspace/in2event/in2event-front-end/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin-_2mggkpqqebct5fvtffn5hjli6e/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/Users/wes/Workspace/in2event/in2event-front-end/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin-_2mggkpqqebct5fvtffn5hjli6e/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/Users/wes/Workspace/in2event/in2event-front-end/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin-_2mggkpqqebct5fvtffn5hjli6e/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/Users/wes/Workspace/in2event/in2event-front-end/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin-_2mggkpqqebct5fvtffn5hjli6e/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/Users/wes/Workspace/in2event/in2event-front-end/node_modules/.pnpm/next@15.1.5_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin-_2mggkpqqebct5fvtffn5hjli6e/node_modules/next/dist/lib/metadata/metadata-boundary.js");
