import {
    type TFunction,
    initializeI18next,
    resourcesToBackend,
    useTranslation,
} from '@in2event/i18n';

const resources = resourcesToBackend(
    // Get the JSON file that matches the locale and namespace
    (lang: string, ns: string) => import(`./locales/${lang}/${ns}.json`),
);

initializeI18next(resources, 'nl');

export { type TFunction, useTranslation };
