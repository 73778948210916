'use client';

import { cn } from '../../utils';
import { Button } from '../button';
import { Command, CommandInput } from '../command';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';
import { ExpandMoreIcon } from '@in2event/icons';
import { useState } from 'react';
import { VirtualizedList } from './virtualized-list';

export interface AutocompleteProps<T, K = { value: unknown; label?: string }> {
    search: string;
    setSearch: (value: string) => void;
    selected: K | undefined;
    onSelect: (value: T) => void;
    items: T[];
    isLoading: boolean;
    getItemLabel: (item: T) => string;
    getItemKey: (item: T) => unknown;
    renderItem: (item: T) => React.ReactNode;
    renderItemSize?: number;
    shouldFilter?: boolean;
    // Text slugs
    placeholder: string;
    searchPlaceholder: string;
    loadingText: string;
    noResultsText: string;
}

export const Autocomplete = <T,>({
    search,
    setSearch,
    selected,
    onSelect,
    items,
    isLoading,
    getItemLabel,
    getItemKey,
    renderItem,
    renderItemSize = 48,
    shouldFilter = false,
    placeholder,
    searchPlaceholder,
    loadingText,
    noResultsText,
}: AutocompleteProps<T>) => {
    const [open, setOpen] = useState(false);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="subtle"
                    role="combobox"
                    aria-expanded={open}
                    size="lg"
                    className={cn(
                        'w-full justify-between border-2 border-neutral-10 bg-neutral-2100 px-3 text-sm font-normal',
                        selected ? 'text-base-900' : 'text-neutral-50',
                    )}
                >
                    {selected?.label ? selected.label : placeholder}
                    <ExpandMoreIcon className="ml-2 size-5 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className="min-w-full p-0 md:min-w-[480px]"
                onFocusOutside={(e) => e.preventDefault()}
                align="start"
            >
                <Command shouldFilter={shouldFilter}>
                    <CommandInput
                        placeholder={searchPlaceholder}
                        value={search}
                        onValueChange={setSearch}
                    />
                    <VirtualizedList
                        search={search}
                        setSearch={setSearch}
                        selected={selected}
                        onSelect={(item) => {
                            onSelect(item);
                            setOpen(false);
                        }}
                        items={items}
                        isLoading={isLoading}
                        getItemLabel={getItemLabel}
                        getItemKey={getItemKey}
                        renderItem={renderItem}
                        renderItemSize={renderItemSize}
                        shouldFilter={shouldFilter}
                        placeholder={placeholder}
                        searchPlaceholder={searchPlaceholder}
                        loadingText={loadingText}
                        noResultsText={noResultsText}
                    />
                </Command>
            </PopoverContent>
        </Popover>
    );
};
