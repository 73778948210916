import type { ComponentPropsWithoutRef } from 'react';
import { createElement } from 'react';
import { cn } from '../utils';

export function extend<T extends { className?: string; ref?: React.Ref<unknown> }>(
    Component: React.ComponentType<T>,
    defaultProps: Partial<T>,
) {
    return function ExtendComponent(props: T) {
        return (
            <Component
                {...defaultProps}
                {...props}
                className={cn(defaultProps.className, props.className)}
            />
        );
    };
}

export function create<T extends keyof HTMLElementTagNameMap>(tag: T) {
    return function CreateComponent(props: ComponentPropsWithoutRef<T>) {
        return createElement(tag, props);
    };
}
