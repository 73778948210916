'use client';

import { cn } from '../../utils';
import { CommandEmpty, CommandGroup, CommandItem, CommandList, CommandLoading } from '../command';
import { CheckIcon } from '@in2event/icons';
import { useCallback, useRef } from 'react';
import { elementScroll, useVirtualizer } from '@tanstack/react-virtual';
import type { VirtualizerOptions } from '@tanstack/react-virtual';
import { AutocompleteProps } from '.';

function easeInOutQuint(t: number) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}

export const VirtualizedList = <T,>({
    selected,
    onSelect,
    items,
    isLoading,
    getItemLabel,
    getItemKey,
    renderItem,
    renderItemSize = 48,
    loadingText,
    noResultsText,
}: AutocompleteProps<T>) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const scrollingRef = useRef<number | null>(null);

    // Smooth scroll to the selected item
    // https://github.com/tanstack/virtual/tree/main/examples/react/smooth-scroll
    const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] = useCallback(
        (offset, canSmooth, instance) => {
            const duration = 1000;
            const start = parentRef.current?.scrollTop || 0;
            const startTime = (scrollingRef.current = Date.now());

            const run = () => {
                if (scrollingRef.current !== startTime) return;
                const now = Date.now();
                const elapsed = now - startTime;
                const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
                const interpolated = start + (offset - start) * progress;

                if (elapsed < duration) {
                    elementScroll(interpolated, canSmooth, instance);
                    requestAnimationFrame(run);
                } else {
                    elementScroll(interpolated, canSmooth, instance);
                }
            };

            requestAnimationFrame(run);
        },
        [],
    );

    const virtualizer = useVirtualizer({
        count: items.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => renderItemSize,
        overscan: 1,
        scrollToFn,
        paddingEnd: items.length > 0 ? 8 : 0,
    });

    const virtualOptions = virtualizer.getVirtualItems();

    return (
        <CommandList
            ref={parentRef}
            style={{
                height: '100%',
                width: '100%',
                overflow: 'auto',
            }}
        >
            {isLoading ? (
                <CommandLoading className="py-3 text-center text-sm">{loadingText}</CommandLoading>
            ) : (
                <CommandEmpty>{noResultsText}</CommandEmpty>
            )}
            <CommandGroup
                className="relative mb-2 w-full"
                style={{
                    height: `${virtualizer.getTotalSize()}px`,
                }}
            >
                {virtualOptions.map((virtualOption) => {
                    const item = items[virtualOption.index];

                    if (!item) {
                        return null;
                    }

                    return (
                        <CommandItem
                            className="absolute left-0 top-0 w-full"
                            style={{
                                height: `${virtualOption.size}px`,
                                transform: `translateY(${virtualOption.start}px)`,
                                cursor: 'pointer',
                            }}
                            key={virtualOption.index}
                            value={getItemLabel(item)}
                            onSelect={() => onSelect(item)}
                        >
                            <CheckIcon
                                className={cn(
                                    'mr-2 size-5',
                                    selected && getItemKey(item) === selected.value
                                        ? 'opacity-100'
                                        : 'opacity-0',
                                )}
                            />
                            {renderItem(item)}
                        </CommandItem>
                    );
                })}
            </CommandGroup>
        </CommandList>
    );
};
