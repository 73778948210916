import { cn } from '../../utils';
import type {
    GroupBase,
    OptionProps,
    ValueContainerProps,
    MenuProps,
    PlaceholderProps,
    ControlProps,
} from 'react-select';
import { components } from 'react-select';

import { Loading } from '../loading';
import { ComponentType } from 'react';

export type OptionType = {
    readonly label: string;
    readonly value: string;
};

export function Option<T, IsMulti extends boolean = false>({
    children,
    className,
    ...props
}: OptionProps<T, IsMulti, GroupBase<T>>) {
    const Option = components.Option as ComponentType<OptionProps<T, IsMulti, GroupBase<T>>>;

    return (
        <Option
            {...props}
            className={cn(
                'flex w-full cursor-pointer items-center text-neutral-650 hover:bg-neutral-2000',
                className,
                {
                    'bg-neutral-2400': props.isSelected,
                    'bg-white': !props.isSelected,
                },
            )}
        >
            {children}
        </Option>
    );
}

export function Placeholder<T, IsMulti extends boolean = false>({
    children,
    ...props
}: PlaceholderProps<T, IsMulti, GroupBase<T>>) {
    const Placeholder = components.Placeholder as ComponentType<
        PlaceholderProps<T, IsMulti, GroupBase<T>>
    >;

    return (
        <Placeholder {...props}>
            <span
                className={cn('truncate text-sm font-medium', {
                    'text-neutral-60': props.isDisabled,
                    'text-neutral-50': !props.isDisabled,
                })}
            >
                {children}
            </span>
        </Placeholder>
    );
}

export function ValueContainer<T, IsMulti extends boolean = false>({
    children,
    className,
    ...props
}: ValueContainerProps<T, IsMulti, GroupBase<T>>) {
    const ValueContainer = components.ValueContainer as ComponentType<
        ValueContainerProps<T, IsMulti, GroupBase<T>>
    >;

    return (
        <ValueContainer {...props} className={cn(className, 'h-[38px]')}>
            {children}
        </ValueContainer>
    );
}

export function Menu<T, IsMulti extends boolean = false>({
    children,
    ...props
}: MenuProps<T, IsMulti, GroupBase<T>>) {
    const Menu = components.Menu as ComponentType<MenuProps<T, IsMulti, GroupBase<T>>>;

    return (
        <Menu {...props}>
            <div className={cn('rounded-xl shadow-sm')}>{children}</div>
        </Menu>
    );
}

export function Control<T, IsMulti extends boolean = false>({
    children,
    ...props
}: ControlProps<T, IsMulti, GroupBase<T>>) {
    const Control = components.Control as ComponentType<ControlProps<T, IsMulti, GroupBase<T>>>;

    return <Control {...props}>{children}</Control>;
}

export function LoadingIndicator() {
    return <Loading className="size-5" />;
}
